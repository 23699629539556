import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import { Link } from 'gatsby';
import libphonenumber from 'google-libphonenumber';
import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/css/intlTelInput.css';
import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { SuccessSvg } from '../../static/svg/invoiceSvgCom';
import ContactBanner from '../components/banner/ContactBanner';
import Footer from '../components/footer/Footer';
import Header from '../components/header/Header';
import Layout from '../components/layout';
import Seo from '../components/seo';

const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();

declare let grecaptcha: any;
declare let gtag: any;
declare let window: any;

declare module 'yup' {
  interface StringSchema {
    phone(value: string): this;
  }
}

interface IFormInputs {
  query_type?: string;
  email?: string;
  full_name?: string;
  organisation?: string;
  mobile_number?: string;
  question?: string;
}

const Contact = () => {
  const [phone, setPhone] = useState<intlTelInput.Plugin>();
  const [show, setShow] = useState(false);
  const [contactObj, setContactObj] = useState<Record<string, any>>({});

  Yup.addMethod(Yup.string, 'phone', function (message) {
    return this.test({
      name: 'phone',
      exclusive: true,
      message: message || 'must be a phone number',
      test: async function (value) {
        if (!phone || !value) {
          return false;
        }
        try {
          const area = phone.getSelectedCountryData().iso2.toUpperCase();
          const number = phoneUtil.parse(value, area);
          return phoneUtil.isValidNumber(number);
        } catch (error) {}
        return false;
      },
    });
  });

  const schema = Yup.object().shape({
    query_type: Yup.string().required('Required'),
    full_name: Yup.string().required('Required'),
    email: Yup.string().email('Invalid email address').required('Required'),
    organisation: Yup.string().required('Required'),
    mobile_number: Yup.string().required('Required').phone('The phone number is invalid.'),
    question: Yup.string().required('Required'),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInputs>({
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    const mobileInput = document.querySelector('#mobile');
    if (mobileInput) {
      const phoneState = intlTelInput(mobileInput, {
        initialCountry: 'auto',
        geoIpLookup: (callback) => {
          axios
            .get<
              undefined,
              {
                data: {
                  country_code: string;
                };
              }
            >('https://ipapi.co/json/', {
              responseType: 'json',
            })
            .then((resp) => {
              if (resp && resp.data && resp.data.country_code) {
                callback(resp.data.country_code.toLowerCase());
              } else callback('sg');
            });
        },
        preferredCountries: ['sg', 'cn', 'hk', 'us'],
      });
      setPhone(phoneState);
    }
  }, []);

  const onSubmit = (data: IFormInputs) => {
    if (!phone) {
      return;
    }

    const area = phone.getSelectedCountryData().dialCode;

    grecaptcha.ready(async () => {
      try {
        const token = await grecaptcha.execute('6Le4KpEaAAAAACJw4liW38ANFo2whC6FjqdWhDdX', {
          action: 'submit',
        });
        const result = await axios.post(
          `${process.env.API_URL}/api/schedule-chats`,
          {
            ...data,
            token,
            mobile: `${area}${data.mobile_number}`,
          },
          {
            responseType: 'json',
          },
        );

        if (result && result.data && result.data.status) {
          const contactObj = {
            email: data?.email,
            phone: `${area}${data.mobile_number}`,
          };
          setContactObj(contactObj);
          setShow(true);
          gtag('set', 'user_data', contactObj);
          gtag('event', 'conversion', {
            send_to: 'AW-10939055673/K2UPCPXIroAYELn8kuAo',
          });
        }
      } catch (error) {}

      // 失败弹窗
    });
  };

  return (
    <Layout>
      <Seo
        title="Contact Us | banco - Get in touch with us today"
        description="Got feedback or questions about banco products or services? Reach out via our contact page. We welcome enquiries on business financing, investor relations or collaboration opportunities"
      />
      <main className="main-wrapper">
        <Header />

        <ContactBanner />

        <div className="section section-contact pb--70">
          <div className="container">
            <div className="row">
              <div className="col content-1">
                <a className="icon-box icon-mobile" href="#contact-form">
                  <img className="light-mode" src="/images/contact/contact-us-icon.png" />
                  <p>Contact Us</p>
                </a>
                <a className="icon-box icon-mobile" href="#faq">
                  <img className="light-mode" src="/images/contact/faq-icon.png" />
                  <p>FAQs</p>
                </a>
                <a
                  className="icon-box icon-mobile"
                  href="#chat"
                  onClick={() => {
                    if (typeof window != 'undefined') {
                      window.HubSpotConversations.widget.open();
                    }
                  }}
                >
                  <img className="light-mode" src="/images/contact/live-chat-icon.png" />
                  <p>Live Chat</p>
                </a>
                <div className="-top">
                  <img className="light-mode" src="/images/contact/contact-us-icon.png" />
                  <div className="text">
                    <p className="-title">Contact Us</p>
                    <p>
                      Provide us with some details, so we can find the best person to answer your
                      query. We will be in touch shortly.
                    </p>
                  </div>
                  <img
                    className="light-mode contact-mobile"
                    src="/images/contact/contact-us-icon.png"
                  />
                </div>
                <form id="contact-form" onSubmit={handleSubmit(onSubmit)}>
                  <label htmlFor="query_type">Query Type</label>
                  <select {...register('query_type')} id="query_type" placeholder="Please select">
                    <option value="InvoicePaid+">InvoicePaid+</option>
                    <option value="Equipment financing">Equipment Financing</option>
                    <option value="Other products">Other products</option>
                    <option value="Partnership opportunities">Partnership opportunities</option>
                    <option value="Media">Media</option>
                    <option value="Others">Others</option>
                  </select>
                  <p>{errors.query_type?.message}</p>

                  <label htmlFor="fullname">Full name</label>
                  <input
                    {...register('full_name')}
                    id="fullname"
                    // name="full_name"
                    placeholder="Enter your full name"
                  />
                  <p>{errors.full_name?.message}</p>

                  <label htmlFor="email">Email address</label>
                  <input
                    {...register('email')}
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Enter your email address"
                  />
                  <p>{errors.email?.message}</p>

                  <label htmlFor="organisation">Your company</label>
                  <input
                    {...register('organisation')}
                    id="organisation"
                    name="organisation"
                    placeholder="Enter your company"
                  />
                  <p>{errors.organisation?.message}</p>

                  <label htmlFor="mobile">Mobile number</label>
                  <input
                    {...register('mobile_number')}
                    id="mobile"
                    name="mobile_number"
                    placeholder="Mobile phone"
                  />
                  <p>{errors.mobile_number?.message}</p>

                  <label htmlFor="question">Questions / Comments</label>
                  <textarea
                    {...register('question')}
                    placeholder="Enter your questions / comments"
                  />
                  <p>{errors.question?.message}</p>
                  <div className="btn-wrap">
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                  </div>
                </form>
              </div>

              <div className="col content-2" id="faq">
                <div className="row">
                  <img className="light-mode" src="/images/contact/faq-icon.png" />
                  <span>FAQs</span>
                  <p>
                    View our comprehensive list of the most common frequently asked questions here.
                  </p>
                  <Link className="banco-btn btn-borderd" to="/faq">
                    Visit FAQs
                  </Link>
                </div>
                <div className="gray-bg" id="chat">
                  <div className="-left">
                    <img className="light-mode" src="/images/contact/whatsapp.png" />
                    <p className="-title">WhatsApp Live Chat</p>
                    <p>
                      Still have some questions about our products and services? Drop us an enquiry
                      or chat message. Our customer service representative are available from 10am
                      to 5:30pm, Mon - Fri.
                    </p>
                    <a className="banco-btn btn-borderd" href="https://wa.me/6562233119">
                      Live chat
                    </a>
                  </div>
                  <div className="-right">
                    <img className="light-mode" src="/images/contact/QR.png" />
                    <p>Scan the QR code to contact us</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal className="contact-modal" show={show} onHide={() => setShow(false)}>
          <Modal.Header closeButton>
            <button onClick={() => setShow(false)}>
              <img src="/images/close-img.png" alt="" />
            </button>
          </Modal.Header>
          <Modal.Body>
            {SuccessSvg({})}
            <p>Thank you for reaching out to banco.</p>
            <p>
              {contactObj.phone && contactObj.email && (
                <>
                  Your submitted email address is <strong>{contactObj.email}</strong>, and your
                  mobile number is <strong>{contactObj.phone}</strong>.
                </>
              )}
            </p>
            <p>We will get back to you as soon as we can.</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={() => setShow(false)}>
              OK
            </Button>
          </Modal.Footer>
        </Modal>

        <Footer />
      </main>
    </Layout>
  );
};

export default Contact;
