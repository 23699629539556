import { Link } from 'gatsby';
import React from 'react';
import { AnimationOnScroll } from 'react-animation-on-scroll';

const ContactBanner = () => {
  return (
    <div
      className="banner banner-contact-style"
      style={{
        backgroundImage: "url('/images/contact/banner-bg.jpg')",
      }}
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="banner-content">
            <AnimationOnScroll animateIn="fadeInUp" animateOnce={true} delay={100}>
              <h1 className="title">Let us know how we can help your business today.</h1>
            </AnimationOnScroll>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactBanner;
